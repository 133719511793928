import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [])
    return (
        <>
            <section className="relative bg-white">
                <img src="contact.jpg" alt="a customer making a phone call to foovante" className="block w-full h-full lg:max-h-[860px] object-cover rounded-es-[7rem] md:rounded-es-[1rem]" />
                <p className="absolute left-3/4 md:left-8 top-1/4 md:top-1/2 w-full max-w-48 md:max-w-md px-8 py-4 p-1 text-2xl md:text-7xl bg-black/50 text-white -translate-x-3/4 md:-translate-x-0 -translate-y-1/4 md:-translate-y-1/2 rounded backdrop-blur">Let&apos;s Get In Touch</p>
            </section>

            {/* Contact Information Box Below Image */}
            <div className="max-w-7xl mx-auto px-8 md:px-12 lg:px-24 mt-8">
                <section className="bg-primary text-white p-8 rounded-xl shadow-lg max-w-lg mx-auto">
                    <div className="flex flex-col gap-12 items-center">
                        <div className="flex flex-col items-center">
                            <p className="-mt-4 text-xl font-semibold">Contact Information</p>
                        </div>
                        <div className="flex flex-col gap-4 text-sm lg:text-base text-white-700">
                            <p>
                                <Link to="tel:+233504609989" className="group flex gap-2 items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" fill="#FFFFFF" className="block w-3 aspect-square">
                                        <path className="fill-white group-hover:fill-black group-focus:fill-black transition-colors" d="M22.386719 18.027344C20.839844 16.703125 19.265625 15.898438 17.738281 17.222656L16.824219 18.019531C16.15625 18.601563 14.914063 21.3125 10.113281 15.785156C5.3125 10.269531 8.167969 9.410156 8.839844 8.835938L9.757813 8.035156C11.277344 6.710938 10.703125 5.042969 9.605469 3.324219L8.945313 2.285156C7.84375 0.574219 6.640625 -0.550781 5.117188 0.769531L4.292969 1.492188C3.617188 1.980469 1.734375 3.578125 1.277344 6.609375C0.726563 10.246094 2.464844 14.414063 6.4375 18.984375C10.40625 23.558594 14.296875 25.855469 17.976563 25.816406C21.035156 25.78125 22.886719 24.140625 23.464844 23.542969L24.289063 22.820313C25.8125 21.5 24.867188 20.152344 23.316406 18.828125Z" fill="#000000" />
                                    </svg>
                                    <span className="group-hover:text-black group-focus:text-black transition-colors">+(233) 504-609989</span>
                                </Link>
                            </p>
                            <p>
                                <Link to="mailto:info@foovante-global.com" className="group flex gap-2 items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="#FFFFFF" className="block w-3 aspect-square">
                                        <path className="fill-white group-hover:fill-black group-focus:fill-black transition-colors" d="M0 7L0 9.78125C3.011719 12.402344 20.289063 27.464844 21.65625 28.65625C23.0625 29.882813 24.464844 30 25 30C25.535156 30 26.9375 29.882813 28.34375 28.65625C29.761719 27.421875 48.046875 11.480469 50 9.78125L50 7 Z M 0 12.4375L0 37.53125L15.125 25.59375C10.222656 21.328125 3.230469 15.25 0 12.4375 Z M 50 12.4375C46.949219 15.09375 39.847656 21.265625 34.875 25.59375L50 37.5 Z M 16.65625 26.9375L0 40.0625L0 43L50 43L50 40.03125L33.34375 26.9375C31.40625 28.625 30.011719 29.847656 29.65625 30.15625C27.738281 31.824219 25.757813 32 25 32C24.242188 32 22.261719 31.828125 20.34375 30.15625C19.992188 29.851563 18.597656 28.628906 16.65625 26.9375Z" fill="#000000" />
                                    </svg>
                                    <span className="group-hover:text-black group-focus:text-black transition-colors">info@foovante-global.com</span>
                                </Link>
                            </p>
                            <p className="flex gap-2 items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="#FFFFFF" className="block w-3 aspect-square">
                                    <path className="fill-white" d="M25 1C16.179688 1 9 8.179688 9 17C9 31.113281 23.628906 47.945313 24.25 48.65625C24.441406 48.875 24.710938 49 25 49C25.308594 48.980469 25.558594 48.875 25.75 48.65625C26.371094 47.933594 41 30.8125 41 17C41 8.179688 33.820313 1 25 1 Z M 25 12C28.3125 12 31 14.6875 31 18C31 21.3125 28.3125 24 25 24C21.6875 24 19 21.3125 19 18C19 14.6875 21.6875 12 25 12Z" fill="#000000" />
                                </svg>
                                <span> Greater Accra, Ghana</span>
                            </p>
                        </div>

                    </div>
                </section>
            </div>
        </>
    )
};

export default Contact;