const changeScrollToTopStateWhenFooterIsEntered = (effectItem, observerEnteredViewport) => {
  if (observerEnteredViewport) {
    effectItem.classList.remove('bg-primary');
    effectItem.classList.add('bg-black');
  } else {
    effectItem.classList.remove('bg-black');
    effectItem.classList.add('bg-primary');
  }
};

const changeScrollToTopStateWhenHeaderIsExited = (effectItem, observerEnteredViewport) => {
  if (!observerEnteredViewport) {
    effectItem.classList.remove('opacity-0');
    effectItem.classList.remove('translate-x-full');
  } else {
    effectItem.classList.add('opacity-0');
    effectItem.classList.add('translate-x-full');
  }
};

export const observeForEffects = (elementToWatchRef, elementForEffectRef, intersectionOptions) => {
  const watch = elementToWatchRef.current;
  const effect = elementForEffectRef.current;

  const observer = new IntersectionObserver((items) => {
    for (const item of items) {
      // Toggle the scroll to top button when the scroll to top marker is off the viewport.
      const observingScrollToTop = item.target.id === 'top'; // Scroll to top marker is the element with an id and id value of 'top'.
      // Toggle scroll to top button if element watched goes off the viewport.
      if (observingScrollToTop) changeScrollToTopStateWhenHeaderIsExited(effect, item.isIntersecting);
        
      // Change scroll to top state when it enters the footer.
      const observingFooter = item.target.localName === 'footer';
      if (observingFooter) changeScrollToTopStateWhenFooterIsEntered(effect, item.isIntersecting);
    }
  }, intersectionOptions);

  observer.observe(watch)
};