import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

/**
 * Return a simple reusable button.
 * @param {object} props Props containing the
 * label and optional utility class names
 * to be used in rendering the button.
 * @returns A button component.
 */
export const SimpleButton = ({ label, className }) => {
  return (
    <button
      className={`btn min-h-fit h-auto py-3 border-none ${className} hover:bg-black hover:text-white focus:bg-black focus:text-white`}
    >
      {label}
    </button>
  );
};

SimpleButton.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

/**
 * Return a simple reusable hyperlink
 * styles as a button.
 * @param {object} props Props containing the
 * href attribute, a label, optional utility
 * class names and target to be used in rendering the
 * hyperlink.
 * @returns A simple hyperlink component
 * styled as a button.
 */
export const SimpleHyperlink = ({
  href,
  label,
  className,
  target = "_self",
}) => {
  return (
    <Link
      to={href}
      target={target}
      className={`btn min-h-fit h-auto py-3 border-none ${className} hover:bg-mydarkblue hover:text-white focus:bg-mydarkblue focus:text-white hover:scale-105 duration-300 cursor-pointer`}
    >
      {label}
    </Link>
  );
};

SimpleHyperlink.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  target: PropTypes.string,
};

/**
 * Return a reusable button with rounded edges.
 * @param {object} props Props containing the
 * label and optional utility class names
 * to be used in rendering the button.
 * @returns A rounded button component.
 */
export const RoundedButton = ({ label, className }) => {
  return (
    <button
      className={`btn min-h-fit h-auto py-3 border-none rounded-full ${className} hover:bg-black hover:text-white focus:bg-black focus:text-white`}
    >
      {label}
    </button>
  );
};

RoundedButton.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

/**
 * Return a simple reusable hyperlink
 * styled as a rounded button.
 *  @param {object} props Props containing the
 * href attribute, a label, optional utility
 * class names and target to be used in rendering the
 * hyperlink.
 * @returns A rounded hyperlink component as
 * a button.
 */
export const RoundedHyperlink = ({
  href,
  label,
  className,
  target = "_self",
}) => {
  return (
    <Link
      to={href}
      target={target}
      className={`btn min-h-fit h-auto py-3 border-none rounded-full ${className} hover:bg-black hover:text-white focus:bg-black focus:text-white`}
    >
      {label}
    </Link>
  );
};

RoundedHyperlink.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  target: PropTypes.string,
};

/**
 * Return a simple reusable hyperlink
 * styled as a rounded button.
 * @param {object} props Props containing a
 * a label and optional utility class names to
 * be used in rendering the div as a button.
 * @returns A rounded div component as
 * a button with hover effects.
 */
export function PrimaryButton({ className, label }) {
  return (
    <div
      className={`px-6 lg:px-10 py-2 lg:py-4 lg:text-xl w-fit mx-auto mt-5 mb-6 rounded-xl text-white font-semibold bg-mydarkblue hover:scale-105 duration-300 cursor-pointer ${className}`}
    >
      {label}
    </div>
  );
}

PrimaryButton.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};
