import React, { useRef, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Header, Footer } from './components';
import { observeForEffects } from './utils/helpers';

function App() {
  const scrollToTopRef = useRef(null);
  const scrollWatcherRef = useRef(null);
  const footerRef = useRef(null);

  useEffect(() => {
    observeForEffects(scrollWatcherRef, scrollToTopRef, {
      rootMargin: '192px 0px 0px 0px'
    });
    observeForEffects(footerRef, scrollToTopRef, {
      rootMargin: '0px 0px -80px 0px'
    })
  })

  return (
    <>
      <span id="top" ref={scrollWatcherRef} className="absolute top-0 block w-full h-24"></span>
      <Header />
      {/* Add spacing between the header and the child to be injected */}
      <section className="my-24">
        <Outlet />
      </section>
      <a ref={scrollToTopRef} href="#top" className="fixed z-50 right-8 lg:right-12 bottom-8 md:bottom-12 lg:bottom-20 flex justify-center items-center w-10 aspect-square p-1 bg-primary opacity-0 translate-x-full transition-all rounded-full drop-shadow-sm">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="#FFFFFF" className="w-full">
          <path d="M25 7.21875L23.59375 8.65625L13.6875 18.53125C12.902344 19.316406 12.902344 20.589844 13.6875 21.375C14.472656 22.160156 15.746094 22.160156 16.53125 21.375L23 14.875L23 40C22.988281 40.722656 23.367188 41.390625 23.992188 41.753906C24.613281 42.121094 25.386719 42.121094 26.007813 41.753906C26.632813 41.390625 27.011719 40.722656 27 40L27 14.875L33.46875 21.375C34.253906 22.160156 35.527344 22.160156 36.3125 21.375C37.097656 20.589844 37.097656 19.316406 36.3125 18.53125L26.40625 8.65625Z" fill="#FFFFFF" />
        </svg>
      </a>
      <Footer footerRef={footerRef} />
    </>
  );
}
export default App;
